/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages

    'common': {
      init: function() {





        // JavaScript to be fired on flexible template pages
        //match height of right carousel to left

        //looks for iframes wraps and adapts the height and width
        (function (window, document, undefined) {
          /*
           * Grab all iframes on the page or return
           */
          var iframes = document.getElementsByTagName('iframe');

          /*
           * Loop through the iframes array
           */
          for (var i = 0; i < iframes.length; i++) {

            var iframe = iframes[i],

            /*
             * RegExp, extend this if you need more players
             */
                players = /www.youtube.com|player.vimeo.com/;

            /*
             * If the RegExp pattern exists within the current iframe
             */
            if (iframe.src.search(players) > 0) {

              /*
               * Calculate the video ratio based on the iframe's w/h dimensions
               */
              var videoRatio = ( iframe.height / iframe.width ) * 100;

              /*
               * Replace the iframe's dimensions and position
               * the iframe absolute, this is the trick to emulate
               * the video ratio
               */
              iframe.style.position = 'absolute';
              iframe.style.top = '0';
              iframe.style.left = '0';
              iframe.width = '100%';
              iframe.height = '100%';

              /*
               * Wrap the iframe in a new <div> which uses a
               * dynamically fetched padding-top property based
               * on the video's w/h dimensions
               */
              var wrap = document.createElement('div');
              wrap.className = 'fluid-vids';
              wrap.style.width = '100%';
              wrap.style.position = 'relative';
              wrap.style.paddingTop = videoRatio + '%';

              /*
               * Add the iframe inside our newly created <div>
               */
              var iframeParent = iframe.parentNode;
              iframeParent.insertBefore(wrap, iframe);
              wrap.appendChild(iframe);

            }

          }

        })(window, document);




          // $(".slidingDiv").hide();

          // $('.show_hide').on("click",function(){
          //   $(this).children('.slidingDiv').slideToggle();
          // });






        var $header = $("header"),
        $clone = $header.before($header.clone().addClass("clone"));
        $(window).on("scroll", function() {
            var fromTop = $(window).scrollTop();
            $("body").toggleClass("down", (fromTop > $('#section1').height()- 60));
        });

                //SCROLL TO ANCHOR TAGS DELAYED FOR EXTERNAL PAGES
        // to top right away
       // if (window.location.hash) scroll(0, 0);
        // void some browsers issue
        setTimeout(function () {
            scroll(0, 0);
        }, 1);

        // any position
        $(function () {
            // your current click function
            $('.scroll').on('click', function (e) {
                //e.preventDefault();
                $('html, body').animate({
                    scrollTop: $($(this).attr('href')).offset().top - 60
                }, 1000, 'swing');
            });
            // *only* if we have anchor on the url
            if (window.location.hash) {
                // smooth scroll to the anchor id
                $('html, body').animate({
                    scrollTop: $(window.location.hash).offset().top - 60
                }, 1000, 'swing');
            }
        });

        new WOW().init();

        $('.match').matchHeight({
            byRow: true,
            property: 'height'
        });

        $('.footerMatch').matchHeight({
            byRow: true,
            property: 'height'
        });

        // $('.nav-primary li a').on('hover', function (f) {
        //   //f.preventDefault();
        //   console.log(this);
        // })

        // $( ".nav-primary li a" ).hover(
        //   function() {
        //     $( this ).next().fadeToggle( "slow", "linear" );
        //   }, function() {

        //     $( this ).next().fadeToggle( "slow", "linear" );
        //   }
        // );


          //SUB MENU REVEAL MOBILE
          if ($(window).width() < 767) {
              var isClicked = "";
              $('.menu-item-has-children').on("click touchstart", function (event) {
                  if (isClicked == "no" || isClicked == "") {
                      event.preventDefault();
                      $(this).children(".nav-drop").fadeIn(750);
                      isClicked = "yes";
                  }
                  else {
                      isClicked = "no";
                  }

              });
          }

          //SUB MENU REVEAL MOBILE
          if ($(window).width() > 768) {
              $('.menu-item-has-children').hover(function() {

                  $(this).children(".nav-drop").fadeIn(750);

                  $(this).children(".nav-drop").fadeOut(750).stop(true,true);

              });
          }


      // if ($(window).width() > 768) {
      //    $( ".nav-primary ul .nav-main-item .nav-main-link" ).mouseover(
      //
      //           function() {
      //             $( ".nav-primary ul .nav-main-item .nav-main-link" ).next().fadeOut( "slow", "linear" );
      //             $( this ).next().fadeIn( "slow", "linear" );
      //           }
      //         );
      // }


      //
      //  $('.nav-primary ul .nav-main-item .nav-main-link').on('touchstart', function (e) {
      //   'use strict'; //satisfy code inspectors
      //   var link = $(this); //preselect the link
      //   if (link.hasClass('hover')) {
      //       return true;
      //   } else {
      //       link.addClass('hover');
      //       $('.nav-primary ul .nav-main-item .nav-main-link').not(this).removeClass('hover');
      //       e.preventDefault();
      //       return false; //extra, and to make sure the function has consistent return points
      //   }
      // });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_page_flexible_php': {
      init: function () {

      },
      finalize: function () {
        // JavaScript to be fired after the init JS
      }
    },
        'blog': {
      init: function () {
       // var numBlog;
       // numBlog = 100/$('.blogItem').size();



      },
      finalize: function () {
        // JavaScript to be fired after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
